// NOTE: Uncomment new languages if needed
const SETS = [
  // {
  //     lngs: [
  //         'ach', 'ak', 'am', 'arn', 'br', 'fil', 'gun', 'ln', 'mfe', 'mg', 'mi', 'oc', 'pt', 'pt-BR',
  //         'tg', 'ti', 'tr', 'uz', 'wa',
  //     ],
  //     nr: [1, 2],
  //     fc: 1,
  // },
  {
    lngs: [
      'af',
      'an',
      'ast',
      'az',
      'bg',
      'bn',
      'ca',
      'da',
      'de',
      'dev',
      'el',
      'en',
      'eo',
      'es',
      'et',
      'eu',
      'fi',
      'fo',
      'fur',
      'fy',
      'gl',
      'gu',
      'ha',
      'he',
      'hi',
      'hu',
      'hy',
      'ia',
      'it',
      'kn',
      'ku',
      'lb',
      'mai',
      'ml',
      'mn',
      'mr',
      'nah',
      'nap',
      'nb',
      'ne',
      'nl',
      'nn',
      'no',
      'nso',
      'pa',
      'pap',
      'pms',
      'ps',
      'pt-PT',
      'rm',
      'sco',
      'se',
      'si',
      'so',
      'son',
      'sq',
      'sv',
      'sw',
      'ta',
      'te',
      'tk',
      'ur',
      'yo',
    ],
    nr: [1, 2],
    fc: 2,
  },
  // {
  //     lngs: [
  //         'ay', 'bo', 'cgg', 'fa', 'id', 'ja', 'jbo', 'ka', 'kk', 'km', 'ko', 'ky', 'lo',
  //         'ms', 'sah', 'su', 'th', 'tt', 'ug', 'vi', 'wo', 'zh'],
  //     nr: [1],
  //     fc: 3,
  // },
  // { lngs: ['be', 'bs', 'dz', 'hr', 'ru', 'sr', 'uk'], nr: [1, 2, 5], fc: 4 },
  // { lngs: ['ar'], nr: [0, 1, 2, 3, 11, 100], fc: 5 },
  { lngs: ['cs', 'sk'], nr: [1, 2, 5], fc: 6 },
  // { lngs: ['csb', 'pl'], nr: [1, 2, 5], fc: 7 },
  // { lngs: ['cy'], nr: [1, 2, 3, 8], fc: 8 },
  // { lngs: ['fr'], nr: [1, 2], fc: 9 },
  // { lngs: ['ga'], nr: [1, 2, 3, 7, 11], fc: 10 },
  // { lngs: ['gd'], nr: [1, 2, 3, 20], fc: 11 },
  // { lngs: ['is'], nr: [1, 2], fc: 12 },
  // { lngs: ['jv'], nr: [0, 1], fc: 13 },
  // { lngs: ['kw'], nr: [1, 2, 3, 4], fc: 14 },
  // { lngs: ['lt'], nr: [1, 2, 10], fc: 15 },
  // { lngs: ['lv'], nr: [1, 2, 0], fc: 16 },
  // { lngs: ['mk'], nr: [1, 2], fc: 17 },
  // { lngs: ['mnk'], nr: [0, 1, 2], fc: 18 },
  // { lngs: ['mt'], nr: [1, 2, 11, 20], fc: 19 },
  // { lngs: ['or'], nr: [2, 1], fc: 2 },
  // { lngs: ['ro'], nr: [1, 2, 20], fc: 20 },
  // { lngs: ['sl'], nr: [5, 1, 2, 3], fc: 21 },
];

const PLURAL_RULERS = {
  // 1: (n) => Number(n > 1),
  2: (n) => Number(n !== 1),
  // 3: (n) => 0,
  // 4: (n) => Number(n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2),
  // 5: (n) => Number(n === 0 ? 0 : n === 1 ? 1 : n === 2 ? 2 : n % 100 >= 3 && n % 100 <= 10 ? 3 : n % 100 >= 11 ? 4 : 5),
  6: (n) => Number(n === 1 ? 0 : n >= 2 && n <= 4 ? 1 : 2),
  // 7: (n) => Number(n === 1 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2),
  // 8: (n) => Number((n === 1) ? 0 : (n === 2) ? 1 : (n !== 8 && n !== 11) ? 2 : 3),
  // 9: (n) => Number(n >= 2),
  // 10: (n) => Number(n === 1 ? 0 : n === 2 ? 1 : n < 7 ? 2 : n < 11 ? 3 : 4),
  // 11: (n) => Number((n === 1 || n === 11) ? 0 : (n === 2 || n === 12) ? 1 : (n > 2 && n < 20) ? 2 : 3),
  // 12: (n) => Number(n % 10 !== 1 || n % 100 === 11),
  // 13: (n) => Number(n !== 0),
  // 14: (n) => Number((n === 1) ? 0 : (n === 2) ? 1 : (n === 3) ? 2 : 3),
  // 15: (n) => Number(n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2),
  // 16: (n) => Number(n % 10 === 1 && n % 100 !== 11 ? 0 : n !== 0 ? 1 : 2),
  // 17: (n) => Number(n === 1 || n % 10 === 1 ? 0 : 1),
  // 18: (n) => Number(n === 0 ? 0 : n === 1 ? 1 : 2),
  // 19: (n) => Number(n === 1 ? 0 : n === 0 || (n % 100 > 1 && n % 100 < 11) ? 1 : (n % 100 > 10 && n % 100 < 20) ? 2 : 3),
  // 20: (n) => Number(n === 1 ? 0 : (n === 0 || (n % 100 > 0 && n % 100 < 20)) ? 1 : 2),
  // 21: (n) => Number(n % 100 === 1 ? 1 : n % 100 === 2 ? 2 : n % 100 === 3 || n % 100 === 4 ? 3 : 0),
};

export function getNrs(lang: string): number[] {
  const set = SETS.find((s) => s.lngs.indexOf(lang) !== -1);
  if (!set) {
    throw new Error('Unknown language');
  }

  return set.nr;
}

function pluralResolve(lang: string, count: number) {
  const set = SETS.find((s) => s.lngs.indexOf(lang) !== -1);
  if (!set) {
    throw new Error('Unknown language');
  }

  return PLURAL_RULERS[set.fc](Math.abs(count));
}

export interface InterpolateOptions {
  count?: number;
  [name: string]: string | number;
}

export const INTERPOLATE_REGEXP = /{{\s*(.*?)\s*}}/g;

export interface Resource {
  readonly key?: string; // Added with LocaleResourceKeyTransformer
  readonly [lang: string]: string | ReadonlyArray<string>;
}

export function interpolate(lang: string, resource: Resource, options?: InterpolateOptions): string {
  const langResource = resource[lang] ?? resource.key;
  if (!options) {
    return Array.isArray(langResource) ? langResource[0] : langResource;
  }

  const needPluralHandling =
    options.count !== undefined && typeof options.count !== 'string' && Array.isArray(langResource);
  let res;

  if (needPluralHandling) {
    res = langResource[pluralResolve(lang, options.count)];
  } else if (Array.isArray(langResource)) {
    res = langResource[0];
  } else {
    res = langResource;
  }

  return res
    ? res.replace(INTERPOLATE_REGEXP, (match, variable) =>
        typeof options[variable] === 'string' || typeof options[variable] === 'number' ? options[variable] : match,
      )
    : '';
}

interface Translation {
  key: string;
  plural: boolean;
  sk: { singular?: string | null; plural1?: string | null; plural2?: string | null };
  cs: { singular?: string | null; plural1?: string | null; plural2?: string | null };
  en: { singular?: string | null; plural1?: string | null };
}

export function translationToResource(translation: Translation): Resource {
  return {
    sk: translation.plural
      ? [
          translation.sk.singular || translation.key,
          translation.sk.plural1 || translation.key,
          translation.sk.plural2 || translation.key,
        ]
      : translation.sk.singular || translation.key,
    cs: translation.plural
      ? [
          translation.cs.singular || translation.key,
          translation.cs.plural1 || translation.key,
          translation.cs.plural2 || translation.key,
        ]
      : translation.cs.singular || translation.key,
    en: translation.plural
      ? [translation.en.singular || translation.key, translation.en.plural1 || translation.key]
      : translation.en.singular || translation.key,
  };
}

export function translationsToResources(translations: Translation[]): { [key: string]: Resource } {
  if (!translations) {
    return {};
  }

  return translations.reduce((acc, cur) => {
    acc[cur.key] = translationToResource(cur);
    return acc;
  }, {});
}
