import { interpolate, InterpolateOptions, Resource } from '@engined/core/services/i18n.js';
import React from 'react';

export type Language = 'sk' | 'cs' | 'en';

export interface LocaleContextValue {
  language: Language;
  t(resource: Resource, options?: InterpolateOptions): string;
  changeLanguage(language: Language);
}

const LocaleContext = React.createContext<LocaleContextValue>({
  language: 'sk',
  t: (resource: Resource, options?: InterpolateOptions): string => interpolate('sk', resource, options),
  changeLanguage(language: Language) {
    this.language = language;
  },
});

LocaleContext.displayName = 'LocaleContext';

export default LocaleContext;

export function useLocale(): LocaleContextValue {
  return React.useContext(LocaleContext);
}

interface Resources {
  [key: string]: Resource;
}

export function createT(lang: string, ...restGlobalResources: Resources[]) {
  return (resource: Resource, options?: InterpolateOptions): string => {
    let res: Resource;
    if (resource.key) {
      res = restGlobalResources.find((globalResources) => globalResources?.[resource.key])?.[resource.key] || resource;
    } else {
      res = resource;
    }

    return interpolate(lang, res, options);
  };
}
